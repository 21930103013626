(function () {
  class CreditScore extends Common {
    constructor() {
      super()
    };

    configure() {
      // BY Ids
      this.ui.submitBtn = "#submitBtn";
      this.ui.deviceNameSelect = "#brand_name";
      this.ui.csrType = "#credit_score_range_csr_type";
      this.ui.scoreFrom = "#credit_score_range_score_from";
      this.ui.scoreTo = "#credit_score_range_score_to";

      //By class
      this.ui.rangeListLink = ".js-range-list-link";
    };

    check(slug) {
      const operation = this;
      operation.hideBootBox();

      $('.bootbox.modal').modal('hide');

      $(operation.ui.submitBtn).attr('disabled', true);
      $(operation.ui.submitBtn).text(i18n.t('commons.processing'));

      bootbox.dialog({
        centerVertical: true,
        closeButton: false,
        message: i18n.t('bootbox.consulting_credit_score_html')
      });

      $.ajax({
        method: 'GET',
        url: Routes.credit_score_credit_infos_path({ slug: slug, format: "js" })
      }).done(function () {
        operation.hideBootBox();
      }).fail(function () {
        operation.hideBootBox();
      });
    };

    checkAgain(slug, person_id) {
      // Metodo usado para API Consentimiento (Preview) - Equifax
      const operation = this;
      $('.bootbox.modal').modal('hide');
      $(operation.ui.submitBtn).attr('disabled', true);
      $(operation.ui.submitBtn).text(i18n.t('commons.processing'));

      Swal.fire({
        html: i18n.t('credit_score.messages.check_again_html'),
        icon: 'success',
        showCloseButton: true,
        showDenyButton: false,
        showCancelButton: true,
        cancelButtonText: i18n.t('commons.cancel'),
        cancelButtonColor: "#dc3545",
        confirmButtonText: i18n.t('commons.check_again'),
        confirmButtonColor: "#1852A9",
        //denyButtonText: i18n.t('commons.preview_consent'),
        //denyButtonColor: "#7066e0",
        allowOutsideClick: false,
        allowEscapeKey: false,
      }).then((result) => {
        if (result.isConfirmed) {
          operation.configure();
          operation.check(slug);
          //} else if (result.isDenied) {
          //  var win = window.open(Routes.preview_customer_consents_path({ person_id: person_id }), '_blank');
          //  win.focus();
          //  operation.configure();
          //  operation.checkAgain(slug, person_id);
        } else {
          window.location.href = Routes.new_customer_consent_path();
        }
      });
    };

    checkFailed(slug, message) {
      const operation = this;
      var failedMessage = i18n.t('bootbox.errors.message_html', { message: message });

      bootbox.confirm({
        message: failedMessage,
        buttons: {
          confirm: {
            label: i18n.t('commons.check_again'),
            className: 'btn-success'
          },
          cancel: {
            label: i18n.t('commons.cancel'),
            className: 'btn-danger'
          }
        },
        callback: function (result) {
          if (result) {
            operation.configure();
            operation.check(slug);
          } else {
            window.location.href = Routes.new_customer_consent_path();
          }
        }
      });
    };

    maxAttempsFailed(message) {
      const operation = this;
      var failedMessage = i18n.t('bootbox.errors.message_html', { message: message + "<br>" + i18n.t('commons.contact_admin') })

      bootbox.alert(failedMessage, function () {
        location.reload();
      });
    };

    approved(slug, message, title) {
      const operation = this;

      Swal.fire({
        html: message,
        icon: 'success',
        showCloseButton: true,
        showDenyButton: true,
        showCancelButton: true,
        cancelButtonText: i18n.t('commons.decline'),
        cancelButtonColor: "#dc3545",
        confirmButtonText: i18n.t('commons.continue_with_loan'),
        confirmButtonColor: "#69BE5A",
        denyButtonText: i18n.t('commons.device_list'),
        denyButtonColor: "#7066e0",
        allowOutsideClick: false,
        allowEscapeKey: false,
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.href = Routes.new_loan_path({ slug: slug });
        } else if (result.isDenied) {
          $.ajax({
            method: "GET",
            url: Routes.device_list_credit_infos_path({ slug: slug }),
            dataType: 'script',
          }).done(function () {
            console.log("Done!");
          }).fail(function () {
            console.log("Error");
          });
        } else {
          window.location.href = Routes.new_customer_consent_path();
        }
      });
    };

    deviceList() {
      const operation = this;
      $(operation.ui.deviceNameSelect).on('change', function () {
        var selected = parseInt($(this).find('option:selected').val());
        var devices = $('.js-root-web').data('approvedDevices');

        var device = devices.filter(function (device) {
          return device.brands.includes(selected) || device.brands.length === 0;
        }).reduce(function (max, device) {
          return device.max_amount > max.max_amount ? device : max;
        });

        $.ajax({
          method: "GET",
          url: Routes.js_device_models_path(),
          data: { object_id: selected, max_price: device['max_amount'] }
          //dataType: 'script',
        }).done(function (data) {
          $("#device-name").html("");
          var resultsArray = data.results
          for (var i = 0; i < resultsArray.length; i++) {
            $('<div class="col-3 dot">').text(resultsArray[i]['text']).appendTo('#device-name');
          };
        }).fail(function () {
          console.log("Error");
        });
      });
    };

    creditScoreRangesList() {
      const operation = this;

      $(operation.ui.rangeListLink).on('click', function () {
        var csr_type = $(this).find('a.nav-link.tab-link').data('id');

        $.ajax({
          method: "GET",
          url: Routes.credit_score_ranges_path({ q: { csr_type_eq: csr_type } }),
          dataType: 'script',
        }).done(function () {
          console.log("done")
        }).fail(function () {
        });
      });
    };

    creditScoreLabel() {
      const operation = this;

      $(operation.ui.csrType).on('change', function () {
        console.log("sd")
        var selected = $(this).find('option:selected').val();

        if (selected == 'credit_score') {
          $(operation.ui.scoreFrom).closest(".form-group").find("label").text(i18n.t('activerecord.attributes.credit_score_range.score_from'));
          $(operation.ui.scoreTo).closest(".form-group").find("label").text(i18n.t('activerecord.attributes.credit_score_range.score_to'));
        } else {
          $(operation.ui.scoreFrom).closest(".form-group").find("label").text(i18n.t('commons.purchased_from'));
          $(operation.ui.scoreTo).closest(".form-group").find("label").text(i18n.t('commons.purchased_up_to'));
        };
      });
    };
  };

  window.CreditScore = CreditScore;
})();

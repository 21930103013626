(function () {
  class Company extends Common {
    constructor() {
      super()
    };

    configure() {
      // BY Ids
      this.ui.provinceId = "#company_province_id";
      this.ui.districtId = "#company_district_id";
      this.ui.jurisdictionId = "#company_jurisdiction_id";
      this.ui.allowCommissions = "input[name*='[allow_commissions]']";
      this.ui.allowCommissionsChecked = "input[name*='[allow_commissions]']:checked";
      this.ui.companyCommission = "#company_commission";
    };

    loadComponents() {
      const operation = this;

      operation.configure();
      operation.needFormValidation();
      operation.loadAllowCommissions();
      operation.loadSelect2Cascade(operation.ui.provinceId, operation.ui.districtId);
      operation.loadSelect2Cascade(operation.ui.districtId, operation.ui.jurisdictionId);

      $(operation.ui.allowCommissions).change();

      $("#company_allow_commissions").rules('add', {
        checkCommission: true
      });
    };

    loadAllowCommissions() {
      const operation = this;

      $(operation.ui.allowCommissions).on('change', function () {
        var selected = Number($(operation.ui.allowCommissionsChecked).val());
        $(operation.ui.companyCommission).addClass("d-none");

        if (selected == 1) {
          $(operation.ui.companyCommission).removeClass("d-none");
        };

      });
    };
  };

  window.Company = Company;
})();
